<script>
export default {
  data() {
    return {
      newFomr: {},
      hasForm: false,
      isEdidtActive: false,
      options: [
        { value: null, text: "Seleccionar" },
        { value: "eliminacion", text: "Eliminación" },
        { value: "sustitucion", text: "Sustitución" },
        { value: "controlDeIngenieria", text: "Control de ingeniería" },
        { value: "controlEnElMedio", text: "Control en el medio" },
        { value: "individuo", text: "Individuo" },
      ],
      estados: [
        { value: null, text: "Seleccionar" },
        { value: "existentes", text: "Existentes" },
        { value: "recomendados", text: "Recomendados" },
      ],
    };
  },
  methods: {
    initNewForm() {
      this.newForm = {
        tipo: null,
        estado: null,
        decripcion: null,
      };
    },
    createForm() {
      this.hasForm = true;
      this.initNewForm();
    },
    cancelForm() {
      this.hasForm = false;
    },
    createControl() {
      if (!this.isEdidtActive) {
        this.controles.push(this.newForm);
      } else {
        this.isEdidtActive = false;
      }

      this.initNewForm();
      this.hasForm = false;
    },
    editarControl(index) {
      this.isEdidtActive = true;
      this.newForm = this.controles[index];
      this.hasForm = true;
    },
    hasControl() {
      const aux = this.controles.find(
        (control) =>
          control.tipo === this.newForm.tipo &&
          control.estado === this.newForm.estado
      );
      if (aux) {
        this.isEdidtActive = true;
        this.newForm = aux;
      }
    },
  },
  computed: {
    controles() {
      return this.$store.state.peligrosYRiesgos.controles;
    },
  },
};
</script>
<template>
  <div>
    <h4 class="text-white"><b>Controles</b></h4>

    <b-list-group id="controles-previos" class="my-3">
      <b-list-group-item v-for="(control, index) in controles" :key="`control${index}`"
        class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1">
            <b> {{ index }} - {{ control.tipo }} - {{ control.estado }} </b>
          </p>
          <small class="pointer" @click="editarControl(index)">Editar</small>
        </div>
        <p class="my-1">
          {{ control.descripcion }}
        </p>
      </b-list-group-item>
    </b-list-group>

    <div class="crear-control my-3">
      <article class="nuevo-control">
        <b-button v-show="!hasForm" @click="createForm" block variant="info">Crear nuevo control</b-button>
      </article>
      <b-form v-if="hasForm" @submit.prevent="createControl">
        <p class="my-2"><b>Nuevo control</b></p>
        <b-row>
          <b-col>
            <b-form-group label="Tipo de control" label-for="tipo">
              <b-form-select @input="hasControl" v-model="newForm.tipo" :options="options" required></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Estado" label-for="estado">
              <b-form-select @input="hasControl" v-model="newForm.estado" :options="estados" required></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Descripción" label-for="descripcion">
          <b-form-textarea v-model="newForm.descripcion" rows="5" max-rows="5" required></b-form-textarea>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <template v-if="!isEdidtActive">
            <b-button class="mr-3" @click="cancelForm" variant="outline-danger">Cancelar</b-button>
            <b-button type="submit" variant="outline-info">Crear</b-button>
          </template>
          <b-button v-else type="submit" variant="info">Guardar control</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>
