<script>
export default {
    computed:{
        formOcurrenciaYEfectos() {
            return this.$store.state.peligrosYRiesgos.formOcurrenciaYEfectos
        },
        efectos() {
            return this.formOcurrenciaYEfectos.efectos
        }
    },
}
</script>
<template>
    <b-form>
        <h4 class="text-white"><b>Ocurrencia</b></h4>
        <b-row>
            <b-col>
                <b-form-group label="Probabilidad" label-for="peligro-probabilidad">
                    <b-form-input id="peligro-probabilidad" v-model="formOcurrenciaYEfectos.probabilidad"
                        placeholder="Agregar probabilidad" type="number" required min="1" max="10"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Exposición" label-for="peligro-exposicion">
                    <b-form-input id="peligro-exposicion" v-model="formOcurrenciaYEfectos.exposicion" placeholder="Agregar exposicion"
                        type="number" required min="1" max="10"></b-form-input>
                </b-form-group>
            </b-col>
		</b-row>

        <h4 class="text-white"><b>Efectos</b></h4>
        <div v-for="efecto in efectos" :key="efecto.name">
            <p class="my-2 fw-light"><b>{{ efecto.title }}</b></p>
            <b-row>
                <b-col class="col-12" lg="3">
                    <b-form-group label="Consecuencia" :label-for="`efectoConsecuencia${efecto.name}`">
                        <b-form-input :id="`efectoConsecuencia${efecto.name}`" v-model="efecto.consecuencia"
                            placeholder="Agregar consecuencia" type="number" required min="1" max="6"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Es requisito legal" v-slot="{ ariaDescribedby }">
                        <b-form-radio class="mb-1" v-model="efecto.requisitoLegal" :aria-describedby="ariaDescribedby"
                            :name="`requisitoLegal${efecto.name}`" value="si">Si</b-form-radio>
                        <b-form-radio class="mt-1" v-model="efecto.requisitoLegal" :aria-describedby="ariaDescribedby"
                            :name="`requisitoLegal${efecto.name}`" value="no">No</b-form-radio>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Descripción" :label-for="`EfectoDescripcion${efecto.name}`">
                        <b-form-textarea :id="`EfectoDescripcion${efecto.name}`" v-model="efecto.descripcion"
                            placeholder="Agregar descripción" rows="5" max-rows="5"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
    </b-form>
</template>