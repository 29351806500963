<template>
  <!-- Cuarto label sale null -->
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-white"><b>Matriz de riesgos y peligros</b></h4>
          </template>
          <div id="jobSearch" class="card-body">

            <!-- Búsqueda de cargo-->

            <JobSearch />

            <div id="peligros">
              <h4 class="text-white mt-4 mb-3"> <b>Peligros</b> </h4>
              <div class="buttons btn-group">
                <button class="btn btn-outline-info btn-lg text-white rounded-top" v-for="peligro in peligros"
                  :class="{ 'active': peligro.title == getSelectedPeligroTitle }" :disabled="getPeligroIsDisabled"
                  @click="selectPeligro(peligro.title)" :key="peligro.title">
                  <b> {{ peligro.text }}</b></button>
              </div>

              <div id="peligro" v-if="!getPeligroIsDisabled">
                <div class="sticky-top">
                  <b-alert :show="dismissCountDown" dismissible variant="danger" @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged">
                    {{ errorMessage }} {{ dismissCountDown }}s
                  </b-alert>
                </div>
                <h4 class="text-white"> <b>{{ getPeligro.text }}</b> </h4>

                <b-list-group class="my-3">
                  <b-list-group-item>
                    <div class="my-2" v-if="getPeligro.descripcion">
                      <b-card-sub-title>Descripción</b-card-sub-title>
                      <p>{{ getPeligro.descripcion }}</p>
                    </div>
                    <div class="my-2" v-if="getPeligro.funteDelPeligro">
                      <b-card-sub-title>Fuente de peligro</b-card-sub-title>
                      <p>{{ getPeligro.funteDelPeligro }}</p>
                    </div>
                  </b-list-group-item>
                </b-list-group>

                <OcurrenciaYEfectos />
                <ControlesPYR />
              </div>
            </div>

            <b-button @click="guardarYContinuar" variant="outline-success">
              Guardar y continuar
            </b-button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ControlesPYR from './ControlesPYR.vue'
import JobSearch from './JobSearch.vue'
import OcurrenciaYEfectos from './OcurrenciaYEfectos.vue'

import { mapGetters } from 'vuex'

export default {
  created() {
    this.$store.dispatch('initData')
  },
  data() {
    return {
      formError: {
        text: '',
        state: false,
        type: 'primary'
      },
      dismissSecs: 5,
      dismissCountDown: 0,
      errorMessage: null

    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    async selectPeligro(peligroTitle) {

      //Antes de cambiar hay que validar 
      const [validation, message] = await this.$store.dispatch('evalModelRequired')

      if (!validation) {
        this.showAlert()
        this.errorMessage = message
        return
      }

      //guardar
      this.$store.dispatch('saveAboutPeligro')

      //cambiar
      this.$store.dispatch('setSelectedPeligroTitle', peligroTitle)

      this.$store.dispatch('setDataFromBackend')
    },
    errorInit() {
      this.formError = {
        text: '',
        type: 'danger',
        state: false
      }
    },
    guardarYContinuar() {
      let peligroTitle = this.getSelectedPeligroTitle
      const index = this.peligros.findIndex(peligro => peligro.title == peligroTitle) + 1
      this.selectPeligro(this.peligros[index].title)
      window.scrollTo(0, 0)
    }
  },

  computed: {
    peligros() {
      return this.$store.state.peligrosYRiesgos.data
    },
    ...mapGetters(['getPeligroIsDisabled', 'getPeligro', 'getSelectedPeligroTitle'])
  },

  components: {
    ControlesPYR,
    JobSearch,
    OcurrenciaYEfectos
  },
}
</script>

<style scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
  border-radius: 3px;
}

.buttons>* {
  border-radius: 0;
}

.validacionEnvio {
  margin: 1rem 0;
}
</style>
